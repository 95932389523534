import classNames from "@calcom/lib/classNames";

export default function LogoLarge({
  inline = true,
  className,
}: {
  inline?: boolean;
  className?: string;
}) {
  return (
    <h3 className={classNames("logo", inline && "inline", className)}>
      <img className={classNames("h-[25vh] w-auto")} alt="FanClub" title="FanClub" src="/large.png" />
    </h3>
  );
}
